const domain = require('./domain.js').domain
const guiDomain = require('./domain.js').guiDomain

module.exports = {
    IMG: `${domain}`,
    VESSELS: `${domain}/vessels`,
    SCHEDULES: `${domain}/schedules`,
    CUSTOMERS: `${domain}/customers`,
    COMMENTS: `${domain}/comments`,
    FILES: `${domain}/files`,
    ZIP: `${domain}/files/zip`,
    LOGOUT: `${domain}/logout?callback=${guiDomain}/`,
    LOGIN: `${domain}/login?callback=${guiDomain}/`,
    guiDomain: guiDomain
}