<template>
  <v-card elevation="4" class="pa-0 ma-0" :color="ownerColor">
    <v-btn
      fab
      icon
      dark
      x-small
      @click="close"
      class="float-right"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <v-card-text class="pa-0 ma-0">
      <v-date-picker
        style="border-radius: 0;"
        show-adjacent-months
        v-model="internalDate"
        :min="getMin"
        :max="getMax"
        :color="ownerColor"
        @change="update()"
      />
  </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "CalendarPopup",
  props: ['value', 'close', 'header', 'min', 'max', 'ownerColor'],
  data: () => ({
    internalDate: null

  }),
  computed: {
    getMin(){
      return this.min
    },
    getMax(){
      return this.max
    }
  },
  mounted: function () {
    if(!this.value) return
    this.internalDate = this.value
  },
  methods: {
    update(){
      this.$emit('input', this.internalDate)
      this.$emit('change', this.internalDate)
    },
    clear() {
      this.internalDate=null
      this.update()
    }
  },
}
</script>

<style lang="scss">
.v-picker__title {
  padding-top: 0;
  padding-bottom: 8px;
}
.v-picker__title__btn.v-date-picker-title__date.v-picker__title__btn--active {
  font-size: 1em;
}
</style>