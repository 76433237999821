<template>
  <v-app-bar height="72px" elevate-on-scroll app :color="getOwnerColor" class="noprint" clipped-left>
    <v-container fill-height fluid >
        <v-row class="d-flex fill-height justify-space-between">
          <div v-if="isTest" style="position:absolute; left: 50%">
                  <p  class="text-h4 font-weight-bold d-none d-md-flex"
                      style="color:red; 
                      text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white;
                      width:384px;
                      position: absolute;
                      left: 45%;
                      ">
                      THIS IS TEST</p>
          </div>
          <v-col cols="auto" class="d-flex pa-0 justify-start ml-6 pr-10">
              <div class="d-flex justify-center mr-8 ">
                <img :src="getLogo" :height="getHeight" />
              </div>
                <menu-bar class="mt-4" />
            </v-col>
            <v-col cols="auto" class="mr-6" v-if="!$vuetify.breakpoint.mdAndDown">
              <span class="d-flex">
                <h3 style="font-family: LatoBlack;" class="white--text mr-1">SAILING</h3>
                <h3 style="font-family: LatoLight;" class="white--text">SCHEDULE</h3>
              </span>
          </v-col>
        </v-row>
    </v-container>
  </v-app-bar>
</template>
<script>
import { test } from '../../config/domain.js';
import MenuBar from './MenuBar.vue'
import { getUserCompany, getOwnerColor } from '../components/Tools'
export default {
  name: "Top",
  props: ['logo', 'color', 'prefix'],
  components: { MenuBar },
  data: () => ({
    loaded: false,
    trade: null,
    searchInput: '',
    fab: false,
    logoMap: {
      'gb': '/gearbulk.png',
      'gs': '/griegstar.png',
      'g2o': '/g2ocean.png'
    },
    heightMap: {
      'gb': '32px',
      'gs': '40px',
      'g2o': '43px'
    },
  }),
  mounted: function () {
      let roles = localStorage.getItem('roles').split(',')
      if(roles.includes('G2O')){
        this.role = 'G2O'
      } else if(roles.includes('GB')) {
        this.role = 'GB'
      }else if(roles.includes('GS')) {
        this.role = 'GS'
      }
    },
  computed: {
    isTest() {
        return test
      },
    getUserCompany, getOwnerColor,
    isLoading(){
      return this.$store.state.loading
    },
    getLogo() {
      return this.logoMap[this.getUserCompany]
    },
    getHeight(){
        return this.heightMap[this.getUserCompany]
    },
  }
}

</script>

<style lang="scss" scoped>
.v-toolbar__content {
    padding: 0 !important;
}
.v-progress-linear__background {
  display: none;
}
</style>