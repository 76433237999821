export function toTitleCase(text) {
    const formatted_text = text ? String(text)
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ") : ''

    return handleRoundBrackets(formatted_text)    
}

function handleRoundBrackets(string) {
  return String(string)
    .split("(")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join("(")
}

export function getShortDate(date, delim) {
    return date ? [date.getFullYear(), new String(date.getMonth() + 1).padStart(2, '0'), new String(date.getDate()).padStart(2, '0')].join(delim) :
    ''
}

export function getLongDate(date, delim) {
    return date ? `${getShortDate(date, delim)} ${[date.getHours(), date.getMinutes()].join(':')}` : ''
}

export function getUserCompany() {
    return localStorage.getItem('roles').split(',').filter(role => !role.endsWith('A')).pop().toLocaleLowerCase()
}
/**
 * @param {*} datestring
 * @returns '21/10/2022 12:00'
 */
export function getISO8601dateFromDate(datestring) {
  if (!datestring) { return '' }
  let date = new Date(datestring)
  const day = ('0' + date.getUTCDate()).slice(-2)
  const month = ('0'+(date.getUTCMonth()+1)).slice(-2)
  const year = date.getFullYear()
  const hh = ('0'+date.getUTCHours()).slice(-2)
  const ii = ('0'+date.getUTCMinutes()).slice(-2)
  return `${day}/${month}/${year} ${hh}:${ii}`
}

/**
 * @param {*} datestring
 * @returns 'Wed Feb 08, 22:00'
 */
export function getUTCDateString(datestring) {
  let date = new Date(datestring)
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
  const dayNames = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
  const day = ('0' + date.getUTCDate()).slice(-2)
  const hh = ('0'+date.getUTCHours()).slice(-2)
  const ii = ('0'+date.getUTCMinutes()).slice(-2)
  return `${dayNames[date.getDay()]} ${monthNames[date.getMonth()]} ${day}, ${hh}:${ii}`
}

export function getOwnerColor(){
    let owner = localStorage.getItem('roles').split(',').filter(role => !role.endsWith('A')).pop().toLocaleLowerCase()
    if (owner == 'gb') {
      return '#254b65'
    } else if (owner == 'gs') {
      return '#1976d2'
    } else {
        return 'rgb(64, 132, 129)'
    }
}

export function getOwnerColorLight(){
    let owner = localStorage.getItem('roles').split(',').filter(role => !role.endsWith('A')).pop().toLocaleLowerCase()
    if (owner == 'gb') {
      return '#b3c8d7'
    } else if (owner == 'gs') {
      return '#c1ddf9'
    } else {
        return '#c3dad9'
    }
}