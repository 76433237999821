<template>
    <v-autocomplete
      v-model="internalValue"
      :items="files"
      label="Select file"
      hide-details
      dense
      outlined
      :color="ownerColor"
      @change="signalChange()"
      class="left"
      no-data-text="No files yet published for trade"
    >
    </v-autocomplete>
</template>

<script>
export default {
  name: "FilesDropdown",
  props: ["files", "handleClick", "value",  "ownerColor"],
  components: {},
  created() {
    setTimeout(() => {
      this.showAlert = false;
    }, 10000);
  },
  data: () => ({
    internalValue: '',
  }),
  computed: {

  },
  mounted: function () {
    if (!this.files) {
      return;
    }
    this.internalValue = this.value
  },
  beforeUpdate() {
    if(!this.internalValue) {
      this.internalValue = this.files[0]
      this.signalChange()
    }
  },
  methods: {
    signalChange(){
      this.$emit('input', this.internalValue)
    }
  },
};


</script>

<style lang="scss">
  .v-list-item__title {
    text-align: left;
}
.rowpointer:hover {
  cursor: pointer;
}
.hilite {
  background-color: lightgray;
}
</style>