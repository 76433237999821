<template>

  <v-row>

    <v-col cols="1" class="mt-2">
      <v-icon
        :color="ownerColor"
      >mdi-calendar-start</v-icon>
    </v-col>

    <v-col>
      <v-menu
        v-model="fromPopup"
        transition="scale-transition"
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-card elevation="0">
            <v-text-field
              :value="prettyStartDate"
              label="From"
              readonly
              hide-details
              style="background-color: rgb(245, 245, 245);"
              :color="ownerColor"
              dense
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </v-card>
        </template>
        <calendar-popup v-model="prettyStartDate" :close="closeFromCalendar" @change="closeFromCalendar();update()" :min="prettyMinDate" :max="prettyEndDate" :ownerColor="ownerColor" />
      </v-menu>
    </v-col>



    <v-col cols="1" class="mt-2">
      <v-icon
        :color="ownerColor"
      >mdi-calendar-end</v-icon>
    </v-col>

    <v-col>
      <v-menu
        v-model="toPopup"
        transition="scale-transition"
        :close-on-content-click="false"
      >
      <template v-slot:activator="{ on, attrs }">
          <v-card elevation="0">
              <v-text-field
              :value="prettyEndDate"
              label="To"
              readonly
              hide-details
              :color="ownerColor"
              dense
              style="background-color: rgb(245, 245, 245);"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </v-card>
        </template>
        <calendar-popup v-model="prettyEndDate" :close="closeToCalendar" @change="closeToCalendar();update()" :max="prettyMaxDate" :min="prettyStartDate" :ownerColor="ownerColor" />
      </v-menu>
    </v-col>

  </v-row>

</template>

<script>
 import CalendarPopup from './CalendarPopup.vue'
import {getShortDate} from './Tools'

export default {
  name: "DateRange",
  props: ['value', 'min', 'max', 'ownerColor'],
  components: { CalendarPopup},
  data: () => ({
    internalInput: {},
    fromPopup: false,
    toPopup: false,
    on: false
  }),
  computed: {

    prettyStartDate: {
      get () {
        return getShortDate(this.internalInput.startDate, '-')
      },
      set (val) {
        if(val){
          this.internalInput.startDate = new Date(val)
        }
      }
    },
    prettyEndDate: {
      get () {
        return getShortDate(this.internalInput.endDate, '-')
      },
      set (val) {
        if(val){
          this.internalInput.endDate = new Date(val)
        }
      }
    },
    prettyMinDate: {
      get () {
        return getShortDate(this.min, '-')
      },
      set (val) {
        if(val){
          this.min = new Date(val)
        }
      }
    },
    prettyMaxDate: {
      get () {
        return getShortDate(this.max, '-')
      },
      set (val) {
        if(val){
          this.max = new Date(val)
        }
      }
    },
  },
  mounted: function () {
    if(!this.value) return
    this.internalInput = this.value
  },
  methods: {
    getShortDate,
    update(){
      this.$emit('input', this.internalInput)
      this.$emit('change', this.internalInput)
    },
    closeToCalendar(){
      this.toPopup = false
    },
    closeFromCalendar(){
      this.fromPopup = false
    }
  },
};


</script>

<style lang="scss">

</style>