<template>
    <v-tabs v-model="tab" dark height="40px" background-color="transparent" show-arrows>
      
      <v-tab :key="getCalendarLink" style="width:120px;" :to="getCalendarLink">Calendar</v-tab>
      <v-tab :key="getPlanningLink" :to="getPlanningLink">Schedule planning</v-tab>
      <v-tab v-if="!isOwner" :key="getScheduleLink" :to="getScheduleLink">By trade</v-tab>
      <v-tab  :key="getPortLink" :to="getPortLink">By port</v-tab>
      <v-tab  :key="getVesselLink" :to="getVesselLink">By vessel</v-tab>
      <v-tab v-if="!isOwner" :key="getShipperLink" :to="getShipperLink">By shipper</v-tab>
      <v-tab  :key="getHistoricalLink" :to="getHistoricalLink">Historical</v-tab>


    </v-tabs>
  </template>
  <script>

  export default {
    name: "MenuBar",
    props: [],
    data: () => ({
      tab: null,
      roles: [],
    }),
    mounted: function () {
      this.roles = localStorage.getItem('roles').split(',')
    },
    computed: {
      isOwner(){
        return !this.roles.includes('G2O')
      },
      getScheduleLink(){
          return `/bytrade/`
      },      
      getVesselLink(){
          return `/byvessel/`
      },
      getHistoricalLink(){
          return `/historical/`
      },
      getPortLink(){
          return `/byport/`
      },
      getCalendarLink(){
          return `/calendar/`
      },
      getShipperLink(){
          return `/byshipper/`
      },      
      getPlanningLink(){
          return `/scheduleplanning/`
      },
    }
  }
  </script>
  
  <style lang="scss">
  .v-tab {
    text-transform: none !important;
  }
  
  .v-tab--active {
    font-weight: bold;
  }
  
  </style>