<template>
  <button @click="exportExcel">
    <slot></slot>
  </button>
</template>

<script>
const ExcelJS = require("exceljs");

export default {
  name: "vue-excel-xlsx",
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    fileName: {
      type: String,
      default: "excel",
    },
    sheetName: {
      type: String,
      default: "SheetName",
    },
    fileType: {
      type: String,
      default: "xlsx",
      validator: (val) => ["xlsx", "xls"].includes(val),
    },
  },

  methods: {
    async exportExcel() {
      if (this.columns.length === 0) {
        console.log("Add columns!");
        return;
      }
      if (this.data.length === 0) {
        console.log("Add data!");
        return;
      }

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet(this.sheetName);

      // Adding Headers
      let headers = this.columns.map((column) => column.label);
      worksheet.addRow(headers);

      // Adding Data Rows with Styles
      this.data.forEach((dataRow) => {
        let row = [];
        this.columns.forEach((column) => {
          let fieldValue = dataRow[column.field];
          if (column.field.split(".").length > 1) {
            fieldValue = this.getNestedValue(dataRow, column.field);
          }
          if (column.dataFormat && typeof column.dataFormat === "function") {
            row.push(column.dataFormat(fieldValue));
          } else {
            row.push(fieldValue);
          }
        });
        const newRow = worksheet.addRow(row);
        newRow.eachCell((cell, colNumber) => {
          cell.alignment = {
            wrapText: this.columns[colNumber - 1].wrapText ?? false,
          };

          worksheet.getColumn(colNumber).width =
            this.columns[colNumber - 1].width ?? 20;
        });
      });

      // Saving to file
      const fileName = `${this.fileName}.${this.fileType}`;

      workbook.xlsx
        .writeBuffer()
        .then((buffer) => {
          const blob = new Blob([buffer]);

          // Create a download link and trigger the download
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((err) => console.log("Error writing excel export", err));
    },

    getNestedValue(object, string) {
      string = string.replace(/\[(\w+)\]/g, ".$1");
      string = string.replace(/^\./, "");
      let a = string.split(".");
      for (let i = 0, n = a.length; i < n; ++i) {
        let k = a[i];
        if (k in object) {
          object = object[k];
        } else {
          return;
        }
      }
      return object;
    },
  },
};
</script>
